:root{
    --primary: #23105e;
    --secondary: #3d3d45;
    --background: #FAF9FF;
}

*{
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

body{
    font-family: 'Quicksand', sans-serif !important;
    background-color: var(--background) !important;
}

input:focus,
button:focus{
    outline: none !important;
}

input::placeholder,
textarea::placeholder{
    color: #bfbfbf !important;
}

a {
    text-decoration: none !important;
}

.text-justify{
    text-align: justify;
}

.w-300{
    width: 300px;
}

.w-80{
    width: 80% !important;
}

.w-5{
    width: 5% !important;
}

.w-20{
    width: 20% !important;
}

.w-68{
    width: 68% !important;
}

.py-11{
    padding: 11px 20px !important;
}

.slick-prev,
.slick-next{
    z-index: 99;
}

.slick-prev {
    left: 25px;
}

.slick-next {
    right: 50px;
}

.slick-prev:before,
.slick-next:before{
    font-size: 36px;
}

.slick-dots{
    bottom: 25px;
    z-index: 99;
}

.slick-dots li{
    margin: 5px;
}

.slick-dots li.slick-active{
    margin: 0px 5px;
}

.slick-dots li button::before{
    font-size: 10px;
    color: #fff;
    filter: drop-shadow(1px 1px 6px #3d3d45);
    opacity: 0.5;
}

.slick-dots li.slick-active button:before{
    color: #fff;
    font-size: 15px;
    opacity: 1;
}

.primary-slider .slick-prev:before,
.primary-slider .slick-next:before{
    color: var(--primary);
}

.primary-slider .slick-prev {
    left: 10px;
}

.primary-slider .slick-next {
    right: 25px;
}

.primary-slider .slick-dots {
    bottom: -20px;
}

.primary-slider .slick-dots li button::before{
    color: var(--primary);
    filter: none;
}

.w-md-50{
    width: 50% !important;
}

.w-md-75{
    width: 75% !important;
}

.upper-roman{
    list-style-type: upper-roman;
}

@media (max-width: 767px) {
    .w-md-50,
    .w-md-75,
    .w-sm-100{
        width: 100% !important;
    }
    .slick-prev {
        left: 5px;
    }
    .slick-next {
        right: 20px;
    }
        .slick-dots{
            bottom: 10px;
        }
}