.custom-table table {
    border-radius: 20px !important;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.065);
    overflow: hidden;
    .ant-table-thead {
        th{
            background-color: var(--primary);
            font-weight: 700;
            font-size: 14px;
            color: #fff;
        }
    }
    .ant-table-tbody{
        td{
            font-weight: 600;
            font-size: 14px;
            .ant-tag{
                font-weight: 600;
                font-size: 14px;
            }
            .action-btn{
                font-size: 14px;
                padding: 12px;
                width: 45px;
                justify-content: center;
                i{
                    margin: 0px;
                }
            }
        }
    }
}

.custom-modal {
    .ant-modal-content{
        padding: 0px;
        border-radius: 18px;
        overflow: hidden;
    }
    .ant-modal-header{
        padding: 15px 20px;
        margin-bottom: 0px;
        background-color: var(--primary);
        .ant-modal-title{
            font-weight: 700;
            font-size: 20px;
            text-align: center;
            color: #fff;
        }
    }
    .ant-modal-body{
        padding: 20px 40px 5px;
    }
    .ant-modal-footer{
        text-align: center;
        padding-bottom: 20px;
        button{
            font-weight: 700;
            font-size: 16px;
            color: #7c7c7c;
            margin-inline-start: 0px !important;
            margin: 0px 10px;
            padding: 6px 25px;
            border-radius: 8px;
            height: auto;
            &:disabled{
                opacity: 0.8;
                color: #fff;
                background-color: var(--primary);
            }
            &.ant-btn-primary{
                color: #fff;
            }
        }
    }
    .ant-modal-close{
        top: 20px;
        color: #fff;
        &:hover{
            background-color: transparent;
            color: #ce0e0e;
        }
        .ant-modal-close-x{
            font-size: 20px;
        }
    }
}

.ant-tooltip-inner{
    font-weight: 600;
}

.ant-notification-notice-message{
    margin-bottom: 0px !important;
}

.custom-table .ant-table{
    background-color: transparent;
}

.custom-table .ant-table .ant-table-tbody{
    background-color: #fff;
}

.custom-confirm{
    .popup-icon{
        display: inline;
        color: #e3c20b;
        font-size: 22px;
    }
    h5{
        font-family: 'Quicksand';
        font-weight: 900;
        font-size: 20px;
        margin-bottom: 0px;
    }
    p{
        font-family: 'Quicksand';
        font-size: 16px;
        margin-bottom: 0px;
        span{
            font-weight: 700;
            color: #810000;
        }
    }
    .ant-modal-confirm-btns{
        margin-top: 20px;
        text-align: center;
        button{
            font-family: 'Quicksand';
            &.btn-red{
                &:hover{
                    background-color: #ce0e0e;
                }
            }
        }
    }
}

.custom-datepicker{
    border: none;
    padding: 10px 15px;
    border-radius: 8px;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.075);
    .ant-picker-input {
        input{
            font-family: 'Quicksand';
            font-size: 16px;
        }
    }
    &.bordered{
        width: 100%;
        border: 1px solid #868686;
        box-shadow: none;
    }
}

.input-error{
    .custom-datepicker{
        border-color: #df1010;
        input{
            border: none;
        }
    }
    .ant-select.custom-select {
        .ant-select-selector{
            border-color: #df1010 !important;
        }
    }
}

.custom-image-loader{
    width: 100% !important;
    height: 300px !important;
    border-radius: 12px !important;
    overflow: hidden;
    &.bg-white{
        position: relative;
        height: 260px !important;
        &::after{
            content: '';
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            border-radius: 12px !important;
            z-index: -1;
            background-color: #ffffff;
        }
    }
}

.ant-pagination.custom-pagination{
    .ant-pagination-item{
      font-weight: 700;
      font-size: 16px;
      color: #000;
      background-color: #fff;
      min-width: 30px;
      height: 30px;
      line-height: 27px;
      &:hover{
        background-color: #d4d4d4;
      }
    }
    .ant-pagination-item-link{
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      color: #7c7c7c;
      border-radius: 6px;
    }
    .ant-pagination-item-active{
      color: #fff;
      background-color: var(--primary);
      &:hover{
        background-color: var(--primary);
      }
    }
    .ant-pagination-disabled .ant-pagination-item-link{
      color: var(--secondary);
    }
    .ant-pagination-prev,
    .ant-pagination-next{
        min-width: 25px;
        height: 30px;
    }
  }

.ant-select.custom-select {
    height: auto !important;
    .ant-select-arrow{
        color: #919191;
    }
    .ant-select-selector{
        width: 100%;
        border: 1px solid #868686 !important;
        border-radius: 6px !important;
        box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.075);
        height: auto !important;
        background-color: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(5px);
        padding: 7px 15px !important;
        .ant-select-selection-search-input {
            font-family: 'Quicksand' !important;
            font-size: 18px;
            color: #000 !important;
            height: auto !important;
            padding: 7px 0px;
        }
        .ant-select-selection-item{
            color: #000;
            font-size: 16px;
        }
    }
    &.p-small{
        .ant-select-selector{
            padding: 1px 10px !important;
        }
    }
    .ant-select-selection-placeholder{
        font-family: 'Quicksand';
        font-size: 16px !important;
        color: #c9c9c9;
        inset-inline-start: 15px !important;
    }
    .ant-select-selection-search{
        margin-inline-start: 0px !important;
    }
}
  

  .ant-select-dropdown .ant-select-item-option-content,
  .ant-select-selection-item{
    font-family: 'Quicksand';
    font-size: 14px;
  }

.ant-collapse{
    background-color: #24105e38;
    .ant-collapse-item {
        border-color: #999999;
            .ant-collapse-header {
                align-items: center;
                font-family: 'Quicksand';
                
                h4,
                .ant-collapse-header-text {
                    color: var(--primary);
                    font-weight: 800;
                    font-size: 20px;
                }

                h4{
                    margin-bottom: 5px;
                }
                h6{
                    color: #4b3197;
                    font-weight: 600;
                    font-size: 16px;
                    margin-bottom: 0px;
                }
            }
    
            .ant-collapse-content{
                .ant-collapse-content-box {
                        p {
                            font-family: 'Quicksand';
                            font-weight: 600;
                            font-size: 16px;
                            margin-bottom: 0px;
                        }
                    }
            }
        &:last-child{
            border-color: transparent;
        }
    }
}

.ant-image .ant-image-mask{
    border-radius: 12px;
}

.ant-breadcrumb.custom-breadcrumb{
    font-family: 'Quicksand';
    font-weight: 600;
    color: #fff;
    .ant-breadcrumb-link,
    .ant-breadcrumb-separator{
        color: #fff;
    }
}

@media (max-width: 767px){
    .custom-table{
        width: 100%;
        .ant-table{
            overflow-x: scroll;
        }
    }

    .pt-m-0{
        padding-top: 0px;
    }

    .ant-collapse .ant-collapse-item .ant-collapse-header .ant-collapse-header-text{
        font-size: 18px;
    }
}