.sticky-header {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    z-index: 9999;
}

header {
    .header-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 0px;
        background-color: var(--primary);

        .info-mail {
            color: #fff;
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 16px;

            i {
                font-size: 18px;
            }
        }

        .logo {
            width: 300px;
        }

        .social-medias {
            display: flex;
            padding: 0px;
            margin: 0px;
            list-style: none;

            li {
                a {
                    font-size: 24px;
                    color: #fff;
                }
            }
        }
    }

    .header-middle {
        padding: 10px 0px 30px;
        background-color: #fff;

        .media-body {
            p {
                font-weight: 600;
                font-size: 14px;
                color: #464646;
                margin-bottom: 0px;
                display: flex;
                align-items: center;

                i {
                    color: var(--primary);
                    font-size: 20px;
                    margin-right: 5px;
                }
            }

            a {
                font-weight: 700;
                font-size: 16px;
                color: var(--primary);
                margin-bottom: 0px;
            }
        }
    }

    .header-nav {
        position: relative;
    }
}

.menuzord-menu {
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
    list-style: none;
    padding: 0px;
    display: flex;
    z-index: 999;
    background-color: #fff;
    box-shadow: -1px -1px 8px #7a7a7a4d;
    border-radius: 8px;

    .menu-header {
        display: none;
    }

    .dropdown {
        position: absolute;
        top: 100%;
        left: -20px;
        transition: 0.2s;
        padding: 0px;
        background-color: #fff;
        box-shadow: 2px 2px 10px #424242;
        width: 0px;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transition: 0.3s;

        li {
            &::after {
                display: none;
            }
        }

        .dropdown {
            top: 0px;
            left: 80%;
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
            transition: 0.3s;
        }
    }

    li {
        position: relative;
        display: inline;
        background-color: #fff;
        transition: 0.2s;

        &::after {
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0px;
            background-color: var(--primary);
            width: 1px;
            height: 20px;
        }

        &:last-child {
            &::after {
                display: none;
            }
        }

        a {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #1a1a1a;
            font-weight: 700;
            font-size: 14px;
            // display: block;
            width: 100%;
            text-wrap: nowrap;
            padding: 12px 18px;
            transition: 0.3s;

            i {
                margin-left: 10px;
            }
        }

        &:hover {
            background-color: var(--primary);

            a {
                color: #e6e6e6 !important;
            }

            >.dropdown {
                width: max-content;
                left: 0px;
                opacity: 1;
                visibility: visible;
                pointer-events: all;
                z-index: 999;

                .dropdown {
                    left: 100%;
                }
            }
        }
    }

    .dropdown {
        li {
            a {
                color: #000 !important;
            }

            &:hover {
                a {
                    // padding-left: 25px;
                    background-color: var(--primary) !important;
                    color: #fff !important;
                }

                .dropdown li a {
                    color: #000 !important;
                    background-color: #fff !important;
                }
            }

            .dropdown li a {
                &:hover {
                    background-color: var(--primary) !important;
                    color: #fff !important;
                }
            }
        }
    }

    &.no-hover .dropdown {
        left: -20px !important;
        transition: 0.2s !important;
        padding: 0px !important;
        width: 0px !important;
        opacity: 0 !important;
        visibility: hidden !important;
        pointer-events: none !important;
    }
}

.slider-wrapper {
    width: 100%;
    min-height: 512px;
}

.slider-items {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    color: #fff;
    text-align: center;
    background-color: #364d79;
    background-position: center;
    background-size: cover;

    &::before {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: #00000067;
    }

    .content {
        position: relative;
        z-index: 9;
    }

    h1 {
        font-weight: 900;
        font-size: 3rem;
        color: #fff;
        margin-bottom: 10px;
        text-shadow: 1px 1px 10px #333333;

        span {
            color: #ff8800;
        }
    }

    h6 {
        font-weight: 600;
        font-size: 20px;
        text-shadow: 1px 1px 10px #333333;
        margin-bottom: 20px;
    }

    button {
        border: 2px solid #fff;
        border-radius: 50px;
        text-shadow: 1px 1px 10px #333333;
        box-shadow: 1px 1px 10px #333333;
        font-weight: 700;
        font-size: 18px;
        padding: 10px 20px;
        color: #fff;
        background-color: transparent;
        transition: 0.3s;

        &:hover {
            text-shadow: none;
            background-color: #fff;
            color: #000;
        }
    }
}

.title-text {
    position: relative;
    font-weight: 800;
    font-size: 34px;
    margin-bottom: 20px;

    span {
        color: var(--primary);
    }

    &::after {
        content: "";
        position: absolute;
        bottom: -10px;
        left: 0px;
        width: 10px;
        height: 10px;
        rotate: 45deg;
        background-color: var(--primary);
    }

    &::before {
        content: "";
        position: absolute;
        bottom: -6px;
        left: 15px;
        width: 150px;
        height: 2px;
        background-color: var(--primary);
    }
}

.subtitle-text {
    color: var(--primary);
    font-weight: 800;
    font-size: 26px;
    margin-bottom: 10px;
}

.about-card {
    position: relative;
    border-radius: 12px;
    box-shadow: 2px 4px 10px #5e5e5e4b;
    overflow: hidden;
    height: 100%;

    img {
        width: 100%;
        height: 220px;
        object-fit: contain;
    }

    h4 {
        a {
            font-weight: 800;
            font-size: 20px;
            color: var(--primary);
        }
    }

    p {
        margin-bottom: 50px;
    }

    .read-more-btn {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        color: var(--primary);
        font-weight: 700;
        font-size: 16px;
        padding: 10px 20px;
        border: 2px solid var(--primary);
        border-radius: 50px;
        transition: 0.3s;

        &:hover {
            color: #fff;
            background-color: var(--primary);
        }
    }
}

.event-card {
    margin-bottom: 20px;

    h6 {
        font-weight: 800;
        font-size: 20px;
        text-align: center;
        margin-top: 5px;
        margin-bottom: 0px;
    }

    img {
        border-radius: 12px;
        overflow: hidden;
    }
}

.footer {
    // margin-top: 70px;
    background-color: #140a33;

    .logo {
        width: 300px;
        margin-bottom: 20px;
    }

    .footer-title {
        font-size: 28px;
        color: #fff;
        font-weight: 800;
        margin-bottom: 20px;
    }

    h5 {
        font-weight: 800;
        font-size: 24px;
        color: #02a34a;
    }

    p {
        font-weight: 600;
        font-size: 16px;
        color: #fff;
        margin-bottom: 10px;
    }

    .footer-link {
        i {
            margin-right: 5px;
        }

        a {
            color: #fff;
            transition: 0.2s;

            &:hover {
                color: #fff9;
            }
        }
    }

    .social-icons {
        list-style: none;
        padding: 0px;
        display: flex;

        li {
            margin-right: 15px;

            a {
                font-size: 30px;
                color: #fff;
                transition: 0.2s;

                &:hover {
                    color: #fff9;
                }
            }
        }
    }

    .footer-bottom {
        background-color: #1e0f4b;
        padding: 15px 0px;

        p {
            color: #ffffffd8;
        }
    }
}

.prayer {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    min-height: 40vh;
    background: url("../img/header-bg.webp") no-repeat;
    background-position-y: 15%;
    background-position-x: center;
    background-size: cover;
    z-index: 0;

    &::after {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.585);
        z-index: -2;
    }

    h2 {
        color: #fff;
        font-weight: 600;
    }

    h6 {
        color: #fff;
        font-weight: 600;
        font-size: 20px;
        margin-top: 20px;
    }

    p {
        color: #fff;
        font-weight: 600;
        text-align: justify;
    }
}

.page-header {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    min-height: 40vh;
    background: url("../img/header-bg.webp") no-repeat;
    background-position-y: 15%;
    background-position-x: center;
    background-size: cover;

    &::after {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.585);
        z-index: 0;
    }

    .content {
        position: relative;
        z-index: 1;
    }

    h2 {
        color: #fff;
        font-weight: 900;
        font-size: 2rem;
        text-transform: uppercase;
        margin-bottom: 10px;
    }
}

.teacher-card,
.student-card,
.contact-card {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    box-shadow: 2px 4px 10px #5e5e5e4b;
    overflow: hidden;

    img {
        width: 100%;
        height: 300px;
        object-fit: cover;
    }

    .card-text {
        width: 100%;
        height: 100%;
        padding: 20px;
        text-align: center;

        h6 {
            color: var(--primary);
            font-weight: 900;
            font-size: 18px;
        }

        p {
            color: #272727;
            font-weight: 700;
            font-size: 14px;
            margin-bottom: 0px;
        }
    }
}

.tab-wrapper {
    display: flex;
    justify-content: center;
    width: max-content;
    padding: 12px 10px;
    margin: 0px auto;
    border-radius: 100px;
    background-color: var(--primary);

    .tab {
        border: none;
        border-radius: 100px;
        padding: 5px 15px;
        margin: 0px 6px;
        background-color: transparent;
        font-weight: 800;
        font-size: 18px;
        color: #fff;
        transition: 0.3s;

        &.active {
            background-color: #fff;
            color: var(--primary);
        }
    }
}

.student-card {
    transition: 0.3s;

    img {
        height: 200px;
        object-fit: contain;
    }

    .card-text {
        background-color: #24105e38;

        h6,
        p {
            color: var(--primary);
        }

        h6 {
            font-weight: 900;
            font-size: 20px;
            margin-bottom: 5px;
        }

        .list-item {
            margin-top: 10px;
            text-align: left;

            span {
                display: flex;
                align-items: center;
                color: var(--primary);
                font-weight: 800;
                font-size: 16px;

                i {
                    font-size: 18px;
                    margin-right: 5px;
                }
            }

            p {
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 10px;
            }
        }
    }
}

.contact-card {
    padding: 20px;

    h6 {
        display: flex;
        align-items: center;
        color: var(--primary);
        font-weight: 900;
        font-size: 24px;
        margin-bottom: 15px;

        i {
            font-size: 30px;
            margin-right: 10px;
        }
    }

    p {
        font-weight: 800;
        font-size: 16px;
        margin-bottom: 0px;
    }
}

.slider-item {
    width: 100%;
    height: 60vh;
    border-radius: 24px;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.sub-text {
    color: #000;
    font-size: 18px;
}

.outline-btn {
    display: block;
    border: 2px solid var(--primary);
    border-radius: 50px;
    padding: 6px 15px;
    color: var(--primary);
    background-color: transparent;
    font-weight: 700;
    font-size: 16px;
    transition: 0.3s;

    &:hover {
        background-color: var(--primary);
        color: #fff;
    }
}

.sticky-left {
    position: sticky;
    top: 100px;
    left: 0px;
}

.list-select {
    list-style: none;
    padding: 0px;
    border-radius: 12px;
    overflow: hidden;

    li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        color: #000;
        padding: 12px 20px;
        font-weight: 700;
        font-size: 18px;
        background-color: #1e0f4b3a;

        &.active {
            color: #fff;
            background-color: var(--primary);

            a {
                color: #fff;
            }
        }
    }
}

.centre-broucher {
    box-shadow: -1px -1px 8px rgba(122, 122, 122, 0.3019607843);
    border-radius: 12px;
    padding: 12px 23px;

    p {
        color: #000;
        font-size: 13px;
        font-weight: 400;
    }

    h5 {
        color: #000;
        font-size: 15px;
        font-weight: 600;
        margin: 0;
    }

    h6 {
        color: var(--primary);
        font-weight: 700;
        font-size: 18px;
    }
}

.network {
    p {
        color: #000;
        font-weight: 600;
        font-size: 17px;
        margin-bottom: 8px;
        text-align: justify;
    }

    ol,
    ul {
        li {
            color: #000;
            font-weight: 500;
            font-size: 16px;
        }
    }

    h2 {
        font-weight: 700;
        font-size: 26px;
        color: var(--primary);
    }

    h6 {
        font-weight: 700;
        font-size: 20px;
        color: #000;
        margin-top: 20px;
    }

    tr {
        font-weight: 500;
    }

    td {
        padding: 5px;
    }
}

.network1 {
    tr {
        font-weight: 500;
        border: solid 1px #000;
    }

    td {
        padding: 5px;
        font-weight: 600;
    }

    strong {
        font-weight: 900;
        font-size: 22px;
    }

    i {
        font-size: 25px;
        color: #000;
    }

    .table-dark {
        --bs-table-bg: #dddddd !important;
        --bs-table-striped-bg: #b1b1b1 !important;
        --bs-table-striped-color: #fff;
        --bs-table-active-bg: #373b3e;
        --bs-table-active-color: #fff;
        --bs-table-hover-bg: #323539;
        --bs-table-hover-color: #fff;
        color: #000 !important;
        border-color: #373b3e;
    }

    .table-striped>tbody>tr:nth-of-type(odd) {
        color: #000 !important;
    }
}

.course-content {
    h1 {
        font-weight: 700;
        font-size: 36px;
        color: var(--primary);
    }

    h2 {
        font-weight: 700;
        font-size: 26px;
        color: var(--primary);
    }

    h5 {
        font-weight: 700;
    }

    h6 {
        font-weight: 700;
        font-size: 20px;
        color: #000;
    }

    p {
        color: #000;
        font-weight: 600;
        font-size: 17px;
        margin-bottom: 8px;
    }

    ol,
    ul {
        li {
            color: #000;
            font-weight: 500;
            font-size: 16px;
        }
    }

    .text-card {
        background-color: var(--primary);
        text-align: center;
        padding: 18px;
        border-radius: 12px;

        p {
            color: #fff;
            font-weight: 600;
            font-size: 16px;
            margin-bottom: 0px;
        }
    }

    td,
    th {
        padding: 15px;
    }
}

.custom-table {
    thead {
        background-color: var(--primary);

        tr th {
            font-weight: 700;
            font-size: 16px;
            text-align: center;
            padding: 10px 15px;
            color: #fff;
            border: 1px solid #fff;
            border-bottom-width: 3px;
        }
    }

    tbody {
        tr {
            &:nth-child(odd) {
                background-color: #1e0f4b3a;
            }

            &:nth-child(even) {
                background-color: #2c176d1a;
            }

            td {
                font-weight: 500;
                font-size: 16px;
                text-align: center;
                padding: 10px 15px;
                color: #000;
                border: 1px solid #fff;
            }
        }
    }

    &.table-text-left {

        thead tr th,
        tbody tr td {
            text-align: left;
        }
    }
}

.network-card {
    img {
        width: 100%;
        height: 300px;
        object-fit: contain;
        margin-bottom: 20px;
    }

    h6 {
        font-weight: 600;
        font-size: 20px;
        color: var(--primary);
    }
}

.online-courses {
    h6 {
        font-weight: 600;
        font-size: 20px;
        color: var(--primary);
        margin-top: 20px;
    }
}

@media (max-width: 767px) {
    header {
        .header-info {
            padding: 5px 10px;

            a {
                font-size: 14px;
            }

            .social-medias li a {
                font-size: 18px;
            }
        }

        .logo {
            width: 300px;
        }

        .header-middle {
            padding-bottom: 10px;
        }

        .menu-btn {
            background-color: transparent;
            border: none;
            padding: 5px 10px;
            font-size: 20px;
        }

        .media-body {
            display: flex;

            p i {
                margin-right: 5px;
            }
        }
    }

    .menuzord-menu {
        display: block;
        position: fixed;
        top: 0px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: 100vh;
        z-index: 9999;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transition: 0.3s;

        &.menu-show {
            opacity: 1;
            visibility: visible;
            pointer-events: all;
            overflow: scroll;
        }

        li::after {
            display: none;
        }

        .dropdown .dropdown {
            top: 100%;
            left: 0px;
        }

        .menu-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 15px 0px;

            h6 {
                font-weight: 800;
                font-size: 24px;
                color: var(--primary);
                margin-bottom: 0px;
            }

            button {
                background-color: transparent;
                font-size: 24px;
                padding: 5px 0px 10px;
                color: #c50101;
                border: none;
            }
        }

        .dropdown li a i {
            rotate: 90deg;
        }

        li a {
            text-wrap: wrap;
        }

        li:hover>.dropdown {
            width: 100% !important;
            left: 0px !important;
            opacity: 1 !important;
            visibility: visible !important;
            pointer-events: all !important;
        }
    }

    .scroll-overflow {
        height: auto;
        overflow-x: hidden;
        overflow-y: scroll;
    }

    .h-sm-300 {
        max-height: 300px !important;
    }

    .h-sm-160 {
        max-height: 160px !important;
    }

    .slider-items {
        height: 50vh;

        h1 {
            font-size: 1.6rem;
        }

        h6 {
            font-size: 16px;
        }

        button {
            font-size: 16px;
        }
    }

    .title-text {
        font-size: 22px;
    }

    .subtitle-text {
        font-size: 20px;
    }

    .footer {
        margin-top: 50px;
    }

    .page-header {
        min-height: 30vh;

        h2 {
            font-size: 1.5rem;
        }
    }

    .tab-wrapper {
        padding: 10px 5px;

        .tab {
            font-size: 13px;
            padding: 5px 10px;
        }
    }
}
.tab.active a{
    color:#23105e
}

.tab a{
    color:#fff;
}
.content button a{
color:#fff
}
.content button a:hover{
color:#23105e;
}
